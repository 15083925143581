@font-face {
  font-family: Gilroy;
  src: url("Gilroy-Bold.04e9f4a7.otf");
  font-weight: 700;
}

@font-face {
  font-family: Gilroy;
  src: url("Gilroy-Medium.3a3a11a4.otf");
  font-weight: 600;
}

body {
  background-color: #fff;
  width: 100vw;
  height: 100vh;
  margin: 0;
  overflow: hidden;
}

.wf-force-outline-none[tabindex="-1"]:focus {
  outline: none;
}

.content {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.content-text {
  text-align: center;
  z-index: 100;
  width: 100%;
  max-width: 592px;
}

.content-text h1 {
  color: #000;
  margin-top: 0;
  margin-bottom: 40px;
  font-family: Gilroy;
  font-size: 60px;
  font-weight: 700;
  line-height: 72px;
}

.content-text h1 span {
  color: #600dd0;
}

.content-text p {
  color: #310d55;
  margin-top: 10px;
  margin-bottom: 24px;
  font-family: Rubik;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
}

.content-text a {
  color: #000;
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.content-logo {
  margin-bottom: 31px;
}

.btn {
  color: #fff;
  cursor: pointer;
  background-color: #310d55;
  border: 1px solid #310d55;
  border-radius: 10px;
  min-width: 185px;
  height: 48px;
  margin-top: 12px;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.btn:hover {
  background-color: #270b44;
  border: 1px solid #270b44;
}

.app-link-buttons {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 48px;
  display: flex;
}

.app-link {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: flex;
}

.qr-code {
  width: 115px;
  height: 115px;
}

@media only screen and (width <= 600px) {
  .content {
    justify-content: center;
    align-items: flex-start;
  }

  .content-text {
    width: auto;
    max-width: 100%;
    padding: 0 24px;
  }

  .content-text h1 {
    margin-bottom: 48px;
    font-size: 36px;
    line-height: 44px;
  }

  .content-text p {
    margin-bottom: 60px;
    font-size: 20px;
    line-height: 30px;
  }

  .content-text a {
    font-size: 14px;
    line-height: 21px;
  }
}

.loader {
  border: 10px solid #f3f3f3;
  border-top-color: #270b44;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin-left: auto;
  margin-right: auto;
  animation: 2s linear infinite spin;
  display: block;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
/*# sourceMappingURL=index.44996d85.css.map */
