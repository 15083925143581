@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-Bold.otf");
  font-weight: 700;
}

@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-Medium.otf");
  font-weight: 600;
}

body {
  background-color: #ffffff;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  margin: 0;
}

.wf-force-outline-none[tabindex="-1"]:focus {
  outline: none;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.content-text {
  width: 100%;
  max-width: 592px;
  text-align: center;
  z-index: 100;
}

.content-text h1 {
  font-family: "Gilroy";
  font-weight: 700;
  font-size: 60px;
  line-height: 72px;
  color: #000000;
  margin-top: 0;
  margin-bottom: 40px;
}

.content-text h1 span {
  color: #600dd0;
}

.content-text p {
  font-family: "Rubik";
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #310D55;
  margin-bottom: 24px;
  margin-top: 10px;
}

.content-text a {
  font-family: "Rubik";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.content-logo {
  margin-bottom: 31px;
}

.btn {
  margin-top: 12px;
  padding: 12px 20px;
  height: 48px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid #310d55;
  background-color: #310d55;
  color: #ffffff;
  border-radius: 10px;
  cursor: pointer;
  min-width: 185px;
}

.btn:hover {
  background-color: #270b44;
  border: 1px solid #270b44;
}

.app-link-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 48;
}

.app-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8;
}

.qr-code {
  height: 115px;
  width: 115px;
}

@media only screen and (max-width: 600px) {
  .content {
    align-items: flex-start;
    justify-content: center;
  }

  .content-text {
    /* margin-top: 169px; */
    width: auto;
    max-width: 100%;
    padding: 0 24px;
  }

  .content-text h1 {
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 48px;
  }

  .content-text p {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 60px;
  }

  .content-text a {
    font-size: 14px;
    line-height: 21px;
  }
}

.loader {
  margin-left: auto;
  margin-right: auto;
  display: block;
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #270b44;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}